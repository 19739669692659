<style lang="scss">
.step-items {
  padding-left: 0px !important;
}
.step-content {
  display: none !important;
}
.step-details {
  margin-top: 7px !important;
}

.step-items:first-child {
  margin-left: -15px;
}

.step-items:last-child {
  margin-right: -15px;
}

.bg-dark {
  .b-steps .steps .step-items .step-item .step-details .step-title {
    color: var(--mdb-gray);
    transition: color 0.3s ease;
  }

  .b-steps .steps .step-items .step-item.is-active .step-details .step-title {
    color: var(--mdb-light);
  }

  .b-steps .steps .step-items .step-item .step-marker {
    color: var(--mdb-light);
    background-color: var(--mdb-dark);
    border-color: var(--mdb-light);
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .b-steps .steps .step-items .step-item.is-active .step-marker {
    background-color: var(--mdb-primary);
    border-color: var(--mdb-primary);
    color: var(--mdb-light);
  }

  .b-steps .steps .step-items .step-item.is-previous .step-marker {
    background-color: var(--mdb-dark);
    border-color: var(--mdb-primary);
    color: var(--mdb-light);
  }

  .b-steps .steps .step-items {
    .step-item::before,
    .step-item::after {
      background: linear-gradient(
        to left,
        var(--mdb-light) 50%,
        var(--mdb-primary) 50%
      );
      background-size: 200% 100%;
      background-position: right bottom;
    }
  }

  .b-steps .steps .step-items {
    .step-item.is-active::before,
    .step-item.is-active::after,
    .step-item.is-previous::before,
    .step-item.is-previous::after {
      background-position: left bottom;
    }
  }
}

.bg-light {
  .b-steps .steps .step-items .step-item .step-details .step-title {
    color: var(--mdb-gray);
    transition: color 0.3s ease;
  }

  .b-steps .steps .step-items .step-item.is-active .step-details .step-title {
    color: var(--mdb-gray-dark);
  }

  .b-steps .steps .step-items .step-item .step-marker {
    color: var(--mdb-gray);
    background-color: var(--mdb-light);
    border-color: var(--mdb-gray);
  }

  .b-steps .steps .step-items .step-item.is-active .step-marker {
    background-color: var(--mdb-primary);
    border-color: var(--mdb-primary);
    color: var(--mdb-light);
  }

  .b-steps .steps .step-items .step-item.is-previous .step-marker {
    background-color: var(--mdb-light);
    border-color: var(--mdb-primary);
    color: var(--mdb-primary);
  }

  .b-steps .steps .step-items {
    .step-item::before,
    .step-item::after {
      background: linear-gradient(
        to left,
        var(--mdb-gray) 50%,
        var(--mdb-primary) 50%
      );
      background-size: 200% 100%;
      background-position: right bottom;
    }
  }

  .b-steps .steps .step-items {
    .step-item.is-active::before,
    .step-item.is-active::after,
    .step-item.is-previous::before,
    .step-item.is-previous::after {
      background-position: left bottom;
    }
  }
}

.inherit-color {
  background-color: inherit !important;
  color: inherit !important;
}
</style>
<template>
  <div>
    <div class="row">
      <div class="col-md-8"></div>
      <div class="col-md-4">
        <h4 class="d-flex justify-content-between align-items-center mb-3">
          <span class="text-muted d-block d-sm-block d-md-none d-lg-none d-xl-none">Track order</span>
          <span class="text-muted d-none d-sm-none d-md-block d-lg-block d-xl-block">Your order</span>
          <span class="badge badge-pill" :class="darkModeBade">
            <span v-if="itemData !== null">{{itemData.order.line_items.length}}</span>
            <span v-else>0</span>
          </span>
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-3">
        <div class="card p-4 pt-5" :class="darkMode">
          <b-steps v-model="progress" :mobile-mode="null" icon-pack="fas" :has-navigation="false" class="progress-report">
            <b-step-item :clickable="false" label="Paid" icon="pound-sign"></b-step-item>
            <b-step-item :clickable="false" label="Cooking" icon="stopwatch"></b-step-item>
            <b-step-item :clickable="false" label="Ready" icon="check"></b-step-item>
            <b-step-item :clickable="false" label="Done" icon="check-double"></b-step-item>
          </b-steps>
        </div>
      </div>
      <div class="col-md-4">
        <ul class="list-group mb-3" style="border-radius: 0.25rem; box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);" :class="darkMode">
          <!-- Item List -->
          <div v-if="itemData != null">
            <template v-for="item in itemData.order.line_items">
              <li :key="item.id" class="list-group-item inherit-color d-flex justify-content-between lh-condensed">
                <div>
                  <h6 class="my-0">{{item.quantity}}x {{item.name}}</h6>
                  <small class="text-muted">No Description</small>
                </div>
                <span class="text-muted">{{convertToDinero(item.total_money.amount)}}</span>
              </li>
            </template>
          </div>
          <div v-else>
            <li class="list-group-item inherit-color d-flex justify-content-center align-items-center lh-condensed">
              <div class="spinner-border text-primary m-1" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </li>
          </div>
          <!-- Item List -->
          <li class="list-group-item inherit-color d-flex justify-content-between">
            <span>Total (GBP)</span>
            <strong v-if="itemData != null">{{convertToDinero(itemData.order.total_money.amount)}}</strong>
            <strong v-else>£0.00</strong>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, Firestore, getFirestore, onSnapshot } from "firebase/firestore";
import { darkModeStyle } from "../../../assets/js/darkMode";
import { getAuth } from "firebase/auth";
import { getRemoteConfigValue } from "@assets/js/remoteConfig";

import Dinero from "dinero.js";

export default {
  data() {
    return {
      itemData: null,
      progress: 0,
      order: null,
      unsub: null,
      snackbar1: null,
      snackbar2: null,
      darkMode: darkModeStyle("bg-dark text-light", "bg-light text-dark"),
      darkModeBade: darkModeStyle("badge-dark", "badge-light"),
      darkModeBtn: darkModeStyle("btn-dark", "btn-light"),
    };
  },
  methods: {
    async fetchOrder(orderID) {

      const apiURL = await getRemoteConfigValue('APIurl');

      const app = this.$firebaseApp;
      const auth = getAuth(app);
      const token = await auth.currentUser.getIdToken();

      const order = await fetch(`${apiURL.asString()}/api/order/${orderID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          "csrf-token": this.$cookies.get("XSRF-TOKEN"),
        }
      });

      if (order.status === 200) {
        return await order.json();
      }

      const error = await order.json();
      var errorMessage;

      if (typeof error.error.message === "string") {
        errorMessage = error.error.message;
      } else {
        errorMessage = "Order not found";
      }

      this.$router.push("/account/orders");
      this.$BuefySimplified.Toast.Danger(errorMessage);

    },
    convertToDinero(price) {
      return Dinero({ amount: price, currency: "GBP" }).toFormat();
    },
  },
  watch: {
    order: {
      handler: function(order) {
        switch (order.fulfillment) {
          case "PROPOSED":
            this.progress = 0;
            break;
          case "RESERVED":
            this.progress = 1;
            break;
          case "PREPARED":
            // "Your order is ready to collect."

            this.snackbar1 = this.$buefy.snackbar.open({
              message: "Your order is ready to collect.",
              type: "is-success",
              position: "is-bottom",
              indefinite: true,
              queue: false
            });

            this.progress = 2;
            break;
          case "COMPLETED":
            //   "Your order has been collected, thank you for your order."
            if (this.snackbar != null) {
              this.snackbar.close();
            }

            this.snackbar2 = this.$buefy.snackbar.open({
              message: "Your order has been collected, thank you for your order.",
              type: "is-success",
              position: "is-bottom",
              indefinite: true,
              queue: false
            });

            this.progress = 3;
            break;
          default:
            this.progress = 0;
        }

        if (this.itemData == null) {
          this.fetchOrder(this.$route.params.id).then(data => {
            this.itemData = data;
          });
        }
      },
    },
  },
  async created() {
    const db = getFirestore(this.$firebaseApp);

    this.unsub = onSnapshot(doc(db, "orders", this.$route.params.id), (doc) => {
      this.order = doc.data();
    });
  },
  destroyed() {
    this.unsub();

    if (this.snackbar1 != null) {
      this.snackbar1.close();
    }

    if (this.snackbar2 != null) {
      this.snackbar2.close();
    }
  },
};
</script>